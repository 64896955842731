



























































































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import 'vue-select/dist/vue-select.css';
import popUpModal from '@/components/form/pop-up-modal.vue';

import { vertragsartItems } from '@/constant/constants';
import { differenceInFullMonths, onCurrencyFocus, recursiveFillData } from '@/helper/functions';
import { exportToPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import ZuersZone from '@/components/form/zuers-zone.vue';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'bauleistung-insurance-basic',
  mixins: [dataChangeMixin],
  components: {
    FormSwitch,
    popUpModal,
    ZuersZone,
  },
  data() {
    return {
      name: 'bauleistung-insurance-basic',
      key: 'CONSTRUCTION_WORK_INSURANCE',
      nextPatch: 'bauleistung-calc-2a-ABN',
      vertragsart: '',
      anschriftNeubau: {
        Auswahl: '',
        Strabe: '',
        Nr: '',
        PLZ: '',
        Ort: '',
        Land: '',
        Zurs: '',
        Starkregenzone: '',
        Naturgefahrenklasse2: '',
        Naturgefahrenklasse3: '',
      },
      baudauer: {
        Versicherungsbeginn: '',
        Baubeginn: '',
        DauerInMonaten: 0,
        Bauende: '',
      },
      recordSumInsured: {
        active: false,
        KG200: null,
        KG300: null,
        KG400: null,
        KG500: null,
        KG600: null,
        KG700: 'Sofern Feuer mitversichert werden soll, bitte bei Baunebenkosten eintragen',
        Versicherungssumme: 0,
      },
      calculation: null,
      noPrice: false,
      price: 0,
      vertragsartItems,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'insuranceFormsApiData'],
  watch: {
    vertragsart: {
      handler(newValue, oldValue) {
        if (newValue === 'Baukombi' || newValue.indexOf('ABN') !== -1) {
          this.nextPatch = 'bauleistung-calc-2a-ABN';
          this.key = 'CONSTRUCTION_WORK_INSURANCE';
          this.$emit('on-key-change', this.key);
        }
        if (newValue.indexOf('ABU') !== -1) {
          this.nextPatch = 'bauleistung-calc-2b-ABU';
          this.key = 'CONSTRUCTION_WORK_ABU_INSURANCE';
          this.$emit('on-key-change', this.key);
        }
      },
    },
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
    vuexZurs(newValue: string) {
      this.anschriftNeubau.Zurs = newValue;
      this.anschriftNeubau.Starkregenzone = newValue;
    },
  },
  methods: {
    ...mapMutations('zuersZoneModule', {
      updatePlace: 'SET_PLACE',
      updatePostalCode: 'SET_POSTAL_CODE',
      updateStreet: 'SET_STREET',
      updateHouseNumber: 'SET_HOUSE_NUMBER',
      updateZurs: 'SET_ZURS',
      resetAddres: 'RESET_STATE',
    }),
    setZuersAddressToStore() {
      this.resetAddres();
      this.updatePlace(this.anschriftNeubau.Ort);
      this.updatePostalCode(this.anschriftNeubau.PLZ);
      this.updateStreet(this.anschriftNeubau.Strabe);
      this.updateHouseNumber(this.anschriftNeubau.Nr);
      this.updateZurs(this.anschriftNeubau.Zurs);
    },
    onCurrencyFocus,
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.basicData) {
        this.vertragsart = this.insuranceFormsApiData.basicData.vertragsart;
        recursiveFillData(this.anschriftNeubau, this.insuranceFormsApiData.basicData.anschriftNeubau);
        recursiveFillData(this.baudauer, this.insuranceFormsApiData.basicData.baudauer);
      }
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData.basicData;
      this.vertragsart = calculationData.vertragsart;
      this.anschriftNeubau = calculationData.anschriftNeubau;
      this.baudauer = calculationData.baudauer;
      this.recordSumInsured = calculationData.recordSumInsured;
    },
    onSubmit(goNextPage = true) {
      this.recordSumInsured.Versicherungssumme = this.kgInsuredSum;
      this.baudauer.DauerInMonaten = this.durationInMonths;

      const data = {
        vertragsart: this.vertragsart,
        anschriftNeubau: this.anschriftNeubau,
        baudauer: this.baudauer,
        recordSumInsured: this.recordSumInsured,
      };

      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Bauleistung-Grunddaten.pdf');
    },
    savePdf() {
      // save insurance, get id and make pdf
      this.$emit('add-to-queue', 'saveBasicPDF', '');
      this.onSubmit(false);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    this.setZuersAddressToStore();
    (this as any).startWatcherActivated();
  },
  computed: {
    mounthDiff(): number {
      return (Number(this.baudauer.Versicherungsbeginn));
    },
    durationInMonths(): number {
      return differenceInFullMonths(this.baudauer.Baubeginn, this.baudauer.Bauende).value;
    },
    durationPeriod(): string {
      return differenceInFullMonths(this.baudauer.Baubeginn, this.baudauer.Bauende).period;
    },
    kgInsuredSum(): number {
      return (+(this.recordSumInsured.KG200 || 0)) +
        (+(this.recordSumInsured.KG300 || 0)) +
        (+(this.recordSumInsured.KG400 || 0)) +
        (+(this.recordSumInsured.KG500 || 0)) +
        (+(this.recordSumInsured.KG600 || 0));
    },
    vuexZurs(): number {
      return this.$store.getters['zuersZoneModule/getZurs'];
    },
  },
});
